import React, { Component } from 'react'
import { JsonEditor } from 'jsoneditor-react'
import { ButtonGroup, Button } from '../buttons'
import 'jsoneditor-react/es/editor.min.css'

const modeTranslations = {
  form: 'Formular',
  tree: 'Baum',
  code: 'Text',
  view: 'Baum',
  text: 'Text',
  preview: 'Text',
}

class Editor extends Component {
  constructor(props) {
    super(props)

    const { readOnly } = props

    const availableEditorModes = []
    if (readOnly) {
      // It should not be possible to switch Editor mode
      availableEditorModes.push('code', 'view')
    } else {
      availableEditorModes.push('code', 'form', 'tree')
    }

    this.editorRef = React.createRef()

    this.state = {
      editorMode: availableEditorModes[0],
      availableEditorModes,
      data: props.data,
    }
  }

  handleChange = (data) => {
    if (Array.isArray(data)) {
      this.setState({ data: [ ...data ] })
    } else {
      this.setState({ data: { ...data } })
    }
  }

  setEditorMode = (mode, e) => {
    e.preventDefault()
    this.editorRef.current.jsonEditor.setMode(mode)
    this.setState({ editorMode: mode })
  }

  render() {
    const { formId, formFieldName, errors, readOnly } = this.props
    const { data, editorMode, availableEditorModes } = this.state

    const setReadOnly = (node) => {
      if (!node.path) {
        // In modes code and text, node is empty: no path, field, or value
        // returning false makes the text area read-only
        return false
      }
    }

    const height = this.props.height ? this.props.height : 400

    return (
      <div>
        <ButtonGroup label="Ansicht">
          {availableEditorModes.map((mode) => (
            <Button
              key={mode}
              name={mode}
              label={modeTranslations[mode]}
              onClick={this.setEditorMode.bind(this, mode)}
            />
          ))}
        </ButtonGroup>

        <input
          hidden
          form={formId}
          name={formFieldName}
          value={JSON.stringify(data)}
          readOnly
        />

        {errors && <p className="inline-errors">{errors}</p>}

        <JsonEditor
          ref={this.editorRef}
          value={data}
          onChange={this.handleChange}
          onEditable={readOnly ? setReadOnly : null}
          history
          mode={editorMode}
          htmlElementProps={{
            style: {
              height,
            },
          }}
        />
      </div>
    )
  }
}

export default Editor

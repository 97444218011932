
import React from 'react'

const Dropdown = ({ options, onChange, value }) => {
  const DropdownItems = () => options ? options.map(o => {
    return <option key={o.value} value={o.value}>{o.label}</option>
  }) : null

  return (
    <select id='chart-dropdown' onChange={onChange} value={value} className="form-select">
      <DropdownItems />
    </select>
  )
}

export default Dropdown
import { kebabCase } from 'lodash'
import React from 'react'
import { IconButton } from '../buttons/IconButton'

const packageLabels = {
  'ssg-central-module': 'ZM Software',
  'ssg-updater': 'ZM Updater',
  'firmware': 'FM Firmware',
  'spiffs': 'FM Spiffs',
  'ssg-fga-st-1-app': 'FM Anwendung',
  'ssg-fga-text': 'FM Texte'
}

export const PackageRow = (props) => {
  const { name, currentVersion, targetVersion, updateAvailable, state, texts } = props

  // const isRunning = state !== "unstarted" && state != undefined && state != "succeeded" && state != "failed"
  const isRunning = state != undefined && state != "succeeded" && state != "failed"
  const showSpinner = isRunning && updateAvailable
  // show checkmark if installing succeeded or if there is no update available
  const showCheckmark = state === "succeeded" || !updateAvailable
  const showFailed = state === "failed"
  const showArrowUp = updateAvailable && !isRunning && !showCheckmark
  const showStateText = showSpinner || showArrowUp || state === "succeeded"

  return <>
    <div>{packageLabels[kebabCase(name)]}</div>
    <div>{currentVersion}</div>
    <div>{updateAvailable}
      { showArrowUp && <i className="fas fa-arrow-up"></i> }
      { showSpinner && <i className="fas fa-spinner fa-spin"></i> }
      { showCheckmark && <i className="fas fa-check"></i> }
      { showFailed && <i class="fas fa-exclamation-triangle"></i> }
    </div>
    <div>{showStateText && texts[state]}</div>
    <div>{targetVersion}</div>
  </>

}
import React, { useState, useEffect, useRef } from 'react'
import 'channels'
import { createConsumer } from '@rails/actioncable'
import camelize from 'camelize'

// useEffect for the jobSubscription was adapted from websocket example here: https://stackoverflow.com/a/60161181/7754966

export const useActionCableSubscription = ({ initialState, cableUrl, token, channel, id }) => {
  const consumer = createConsumer(cableUrl)
  const subscription = useRef(null)
  const [currentState, setCurrentState] = useState(initialState)

  useEffect(() => {
    if (id == null || id == undefined) return
    console.log('creating subscription', { channel, id })
    subscription.current = consumer.subscriptions.create({ channel, id, token })
    subscription.current.received = (data) => {
      setCurrentState(camelize(data))
    }
    subscription.current.connected = () => console.log(`connected to channel ${channel} with id ${id}`)
    subscription.current.disconnected = () => console.log(`disconnected from ${channel} with id ${id}`)

    const currentSubscription = subscription.current

    // return cleanup function
    return () => {
      console.log(`unsubscribing channel ${channel} with id ${id}`)
      currentSubscription.unsubscribe()
    }
  }, [id])

  return currentState
}

export default useActionCableSubscription

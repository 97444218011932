import React, { useState } from 'react'
import Dropdown from './Dropdown'
import { useActionCableSubscription } from './hooks'
import camelize from 'camelize'
import { kebabCase } from 'lodash'

// TODO: maybe move this to i18n files
const packageLabels = {
  'ssg-central-module': 'Central Module Software:',
  'ssg-updater': 'Central Module Updater:'
}

export const CentralModuleSoftwareUpdate = (props) => {
  const { installablePackages, installPath, csrfToken, apiToken, centralModule, cableUrl } = props
  const [selectedPackage, setSelectedPackage] = useState({ Version: '1.0.0' })
  const [selectedPackageName, setSelectedPackageName] = useState('none')
  const [selectedPackageVersion, setSelectedPackageVersion] = useState('none')
  const [currentJob, setCurrentJob] = useState({ id: null })
  const centralModuleState = useActionCableSubscription({
    channel: 'CentralModuleChannel',
    id: centralModule.id,
    token: apiToken,
    cableUrl: cableUrl,
    initialState: {
      updaterStatus: {
        timestamp: '',
        packages: [
          { name: 'ssg-central-module', version: '' },
          { name: 'ssg-updater', version: '' }
        ],
        online_status: '',
        update_status: ''
      }
    }
  })
  const availablePackages = camelize(installablePackages, 1)
  const { updaterStatus } = camelize(centralModuleState)
  const currentPackages = updaterStatus.packages
  const jobState = useActionCableSubscription({ channel: 'StatefulJobChannel', id: currentJob.id, token: apiToken, cableUrl: cableUrl })

  const packageOptions = [
    { value: 'none', label: 'Bitte wählen...' },
    ...availablePackages.packageNames.map(x => { return { value: x, label: x } })
  ]
  let versionOptions = []
  console.log(availablePackages)
  if (selectedPackageName !== 'none') {
    versionOptions = availablePackages.versionsByPackageName[camelize(selectedPackageName)].map(x => { return { value: x, label: x } })
  }

  const handleInstall = (e) => {
    e.preventDefault()

    if (selectedPackage === '') {
      window.alert('Please select the target version first.')
      return
    }

    window
      .fetch(
        installPath,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${apiToken}`,
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
          },
          credentials: 'same-origin',
          body: JSON.stringify({
            package_name: kebabCase(selectedPackageName),
            package_version: selectedPackageVersion
          })
        }
      )
      .then((response) => response.json())
      .then(job => setCurrentJob(job))
      .catch((error) => console.error('Error:', error))
  }

  const handlePackageChange = (e) => { setSelectedPackageName(e.target.value) }
  const handleVersionChange = (e) => { setSelectedPackageVersion(e.target.value) }

  return (
    <div>
      <div className='col col-xs-12 col-md-6 col-lg-4'>
        <div className='input-group'>
          <Dropdown onChange={handlePackageChange} options={packageOptions} value={selectedPackageName} />
          <Dropdown onChange={handleVersionChange} options={versionOptions} value={selectedPackageVersion} />
          <input
            className='btn btn-primary'
            onClick={handleInstall}
            name='install'
            value='Install'
            type='submit'
          />
        </div>
      </div>

      {updaterStatus && <div className='container-fluid'>
        {currentPackages && currentPackages.map(p => <div className='row justify-content-start pt-2' key={p.name}>
          <div className='col-sm-4 col-lg-2'>{packageLabels[p.name]}</div>
          <div className='col-sm-4 col-lg-2'>{p.version}</div>
                                       </div>)}

        <div className='row justify-content-start pt-2'>
          <div className='col-sm-4 col-lg-2'>UpdaterService-Status</div>
          <div className='col-sm-4 col-lg-2'>{updaterStatus.onlineStatus}</div>
        </div>
        <div className='row justify-content-start pt-2'>
          <div className='col-sm-4 col-lg-2'>Update-Status</div>
          <div className='col-sm-4 col-lg-2'>{updaterStatus.updateStatus}</div>
        </div>
        <div className='row justify-content-start pt-2'>
          <div className='col-sm-4 col-lg-2'>Timestamp</div>
          <div className='col-sm-4 col-lg-2'>{updaterStatus.timestamp}</div>
        </div>
      </div>}
    </div>
  )
}

export default CentralModuleSoftwareUpdate

import React from 'react'
import styled, { keyframes } from 'styled-components'

const rotateAnimation = keyframes`
 0% { transform: rotate(0deg); }
 50% { transform: rotate(180deg); }
 100% { transform: rotate(360deg); }
`

const RotatingIcon = styled.div`
  animation-name: ${rotateAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`

export const IconButton = ({ name, label, onClick, icon, id, disabled, loading }) => {
  const buttonClasses = loading ? 'c-icon-button loading' : 'c-icon-button'
  const buttonWrapperClasses = disabled ? 'c-button-wrapper-disabled' : 'c-button-wrapper'
  const iconWrapperClasses = label ? 'c-icon-with-label' : ''

  return (
    <div className={buttonWrapperClasses} onClick={() => onClick(id)} disabled={disabled}>
      <button className={buttonClasses} type='submit' name={name} disabled={disabled}>
        {loading ? <RotatingIcon><div className={iconWrapperClasses}><i className={icon} /></div></RotatingIcon> : <div className={iconWrapperClasses}><i className={icon} /></div>}
        {label}
      </button>
    </div>
  )
}
